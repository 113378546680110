<template>
  <div class="mb-6 rounded-2xl bg-gray-50 px-4 py-3 md:px-6 md:py-4">
    <div class="mb-1 flex items-center justify-between gap-x-1 text-sm text-gray-900">
      <template v-if="hasTherapyUpgrade">
        <span class="font-medium text-gray-900">Bist du bereits Plus-Mitglied?</span>
      </template>
      <template v-else-if="hasPediatricsAbo">
        <span class="font-medium text-gray-900">Ist dein Kind bereits Mitglied?</span>
      </template>
      <template v-else>
        <span class="font-medium text-gray-900">Bist du bereits Mitglied?</span>
      </template>
      <AppButton
        class="shrink-0 bg-white !px-2 !py-2 !text-xs font-medium md:!px-4"
        variant="outline"
        @click="membershipDrawerStore.open(bookingStore.servicePrice, discount, bookingStore.department?.title)">
        Vorteile ansehen
        <SvgoExternalLink class="ml-1 text-base" />
      </AppButton>
    </div>
    <div class="mt-2">
      <fieldset>
        <legend class="sr-only">haelsi Mitgliedschaft</legend>
        <div class="flex items-center gap-x-2">
          <AppRadioBox
            v-model="hasMembershipBenefitsSelected"
            class="flex-1 bg-white"
            :value="true"
            name="hasMembership"
            data-track-booking-form-membership="true">
            <p class="flex items-center justify-between">
              <span>Ja</span>
              <AppPill v-if="hasDiscount" class="bg-green-100 font-medium text-green-700">
                <span class="md:hidden">&#045; {{ discount }} &percnt;</span>
                <span class="hidden md:block">{{ discount }} &percnt; Rabatt</span>
              </AppPill>
            </p>
          </AppRadioBox>
          <AppRadioBox
            v-model="hasMembershipBenefitsSelected"
            class="flex-1 bg-white"
            :value="false"
            name="hasMembership"
            data-track-booking-form-membership="false">
            Nein
          </AppRadioBox>
        </div>
      </fieldset>
    </div>
    <div
      v-if="globalStore.isActivePromotion === false"
      class="max-h-24 overflow-hidden transition-all duration-300 ease-in-out"
      :class="{ '!max-h-0': hasMembershipBenefitsSelected === true }">
      <p class="mt-4 font-medium">
        Aktiviere jetzt deinen Gratis-Monat und spare sofort
        <span class="text-green-600">{{ transformToCurrency(savings) }}</span>
      </p>
      <AppToggle v-model="wantToBuyMembership" class="mt-2" name="buyMembership">
        Gratis-Monat aktivieren <br />
        <span class="text-xs text-gray">Kein Risiko - jederzeit kündbar</span>
      </AppToggle>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "~/store/global";

const bookingStore = useBookingStore();
const globalStore = useGlobalStore();

const membershipDrawerStore = useMembershipDrawerStore();

const { discount, hasMembershipBenefitsSelected, savings, wantToBuyMembership } = storeToRefs(bookingStore);
const hasDiscount = computed(() => isNotNullOrUndefined(discount.value) && discount.value !== 0);
const hasTherapyUpgrade = computed(() => hasHigherDiscount(bookingStore.department?.title));
const hasPediatricsAbo = computed(() => isPediatricsDepartment(bookingStore.department?.title));
</script>

<style scoped></style>
