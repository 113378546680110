import { isNotNullOrUndefined } from "~/utils/typeGuards";

export function getServicePrice(price: number, discount?: number): number {
  if (isNotNullOrUndefined(discount) && discount !== 0) {
    const discountAmount = (discount / 100) * price;

    return price - discountAmount;
  }

  return price;
}
