<template>
  <ClientOnly>
    <UiSpinner v-if="loading" />
    <div :class="{ invisible: loading }">
      <iframe :src="customBookingUrl" title="Buchungsformular" class="min-h-[1050px] w-full" @load="iframeLoaded" />
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
defineProps<{
  customBookingUrl: string;
}>();

const loading = ref(true);

function iframeLoaded(): void {
  loading.value = false;
}
</script>

<style scoped></style>
