<template>
  <div
    class="w-full cursor-pointer rounded-lg border border-gray-600 bg-white px-4 py-2 text-sm transition duration-300 ease-in-out hover:bg-gray-50"
    @click="toggleReimbursementModal">
    <div class="flex items-center justify-between">
      <p class="flex items-center gap-x-1 text-gray-600">Erhalte bis zu</p>
      <SvgoArrowRight class="-rotate-45 text-base text-gray-800" />
    </div>
    <p class="text-base font-medium text-gray-800">{{ reimbursement }}&nbsp;&#037; zurück</p>
  </div>
</template>

<script setup lang="ts">
import { useReimbursementModalStore } from "~/store/reimbursementModal";

const props = defineProps<{
  reimbursement: number | string | null | undefined;
}>();

const reimbursementStore = useReimbursementModalStore();
function toggleReimbursementModal() {
  const reimbursement = props.reimbursement ?? 0;
  const reimbursementNumber = typeof reimbursement === "string" ? parseInt(reimbursement, 10) : reimbursement;

  reimbursementStore.setReimbursement(reimbursementNumber);
  reimbursementStore.toggleModal();
}
</script>

<style scoped></style>
