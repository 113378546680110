<template>
  <label
    class="flex h-11 cursor-pointer items-center gap-x-2 rounded-md border border-brick-800 px-4 py-2 text-sm text-gray-900 transition hover:border-yellow-600">
    <input
      v-model="model"
      type="radio"
      :name="name"
      :required="required"
      :value="value"
      class="h-4 w-4 cursor-pointer border-brick-800 text-brick-700 focus:ring-2 focus:ring-brick-300" />
    <div class="w-full">
      <slot />
    </div>
  </label>
</template>

<script setup lang="ts">
const model = defineModel<string | boolean | undefined>({ required: true });

withDefaults(
  defineProps<{
    value: string | boolean;
    name: string;
    required?: boolean;
  }>(),
  {
    required: false,
  },
);
</script>

<style scoped></style>
