<template>
  <div class="flex cursor-pointer" @click="onClickToggle">
    <input :id="checkboxId" v-model="model" type="checkbox" :name="name" class="peer sr-only" :value="model" />
    <div
      class="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-yellow-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300"></div>
    <label :for="checkboxId" class="ms-3 text-sm text-green-600"><slot /></label>
  </div>
</template>

<script setup lang="ts">
const checkboxId = useId();
const model = defineModel<string | boolean | null>({ required: true });

defineProps<{
  name: string;
}>();

function onClickToggle(): void {
  model.value = !model.value;
}
</script>

<style scoped></style>
